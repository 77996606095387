<template>
  <v-row dense>
    <v-col>
      <v-autocomplete
        :items="filteredLabs"
        :filter="customFilter"
        :rules="rules.required"
        label="Select a Lab"
        no-data-text="No matches were found"
        item-text="title"
        item-value="id"
        :value="value"
        @input="emitChange"
        :disabled="disabled"
      >
        <template v-slot:label>
          <h4>Select a Lab</h4>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import rules from "@/utils/rules";

export default {
  name: "LabSelection",
  props: {
    value: Number,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        required: [rules.required]
      }
    };
  },
  computed: {
    ...mapGetters("baseData", ["labs"]),
    filteredLabs() {
      return this.labs.filter(lab => lab.is_active === true);
    }
  },
  methods: {
    customFilter(item, queryText) {
      const title = item.title.toLowerCase();
      const searchText = queryText.toLowerCase();

      return title.indexOf(searchText) > -1;
    },
    emitChange(value) {
      this.$emit("input", value);
    }
  }
};
</script>
