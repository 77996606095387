<template>
  <div>
    <template v-if="loading">
      <v-row dense>
        <v-col>
          <LoadingBar />
          <v-text-field
            type="hidden"
            value=""
            :rules="[v => !!v]"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="items.length">
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            label="Shipment"
            placeholder="Create New Shipment"
            v-bind:value="value"
            v-on:input="setValue($event)"
            :items="items"
            item-text="id"
            item-value="id"
            :clearable="clearable"
            no-data-text="No Available Shipments"
            auto-select-first
            :disabled="disabled"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                @click="data.select"
              >
                <!--              <v-avatar left color="primary">-->
                <!--                <span class="white&#45;&#45;text">{{ data.item.samples.length }}</span>-->
                <!--              </v-avatar>-->
                #{{ data.item.id }}
              </v-chip>
            </template>

            <template v-slot:item="data">
              <!--                          <v-list-item-avatar color="primary">-->
              <!--                            <span class="white&#45;&#45;text">{{  }}</span>-->
              <!--                          </v-list-item-avatar>-->
              <v-list-item-content>
                <v-list-item-title v-html="data.item.id"></v-list-item-title>
                <v-list-item-subtitle>
                  Shipment Created Date: {{ data.item.created }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Samples in Shipment: {{ data.item.samples.length }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row
        dense
        v-if="(!!initialInfo && !anyChanges) || (showCreateNew && !!value)"
      >
        <v-col v-if="!!initialInfo && !anyChanges"> {{ initialInfo }} </v-col>
        <v-col v-if="showCreateNew && !!value" class="text-center">
          <v-btn color="primary" @click="setValue(null)">
            Start New Shipment
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-if="!loading && !value && clearable">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-card-title>Shipment Info</v-card-title>
            <v-card-text v-if="!value" class="body-1">
              Adding to new shipment
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LoadingBar from "@/components/loaders/LoadingBar";

export default {
  name: "ShipmentSelect",
  props: {
    value: [String, Number],
    lab_id: [String, Number],
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    initialInfo: {
      type: String,
      default: ""
    },
    showCreateNew: {
      type: Boolean,
      default: false
    }
  },
  components: { LoadingBar },
  data() {
    return {
      loading: false,
      anyChanges: false,
      items: []
    };
  },
  watch: {
    lab_id() {
      this.populateShipments();
    }
  },
  methods: {
    ...mapActions("shipments", ["getUnshipped"]),
    setValue(val) {
      this.anyChanges = true;
      this.$emit("input", val);
    },
    populateShipments() {
      this.loading = true;
      let params = {};
      // Filter by lab_id if one was provided
      if (this.lab_id && parseInt(this.lab_id) > 0) params.lab_id = this.lab_id;
      return this.getUnshipped(params).then(({ data }) => {
        // Populate the shipments
        this.items = data.data;
        // If a value was not provided
        if (!this.value || !(parseInt(this.value) > 0)) {
          // and there is a shipment available
          if (this.items.length) {
            // Pre-select the first shipment
            this.setValue(this.items[0].id);
          }
        } else {
          // If selected shipment isn't available in the dataset, then we should unset it
          const shipment = this.items.find(item => item.id === this.value);
          if (shipment === undefined) {
            this.setValue(null);
          }
        }
        this.loading = false;
      });
    }
  },
  mounted() {
    this.populateShipments();
  }
};
</script>

<style scoped></style>
