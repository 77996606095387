<template>
  <v-card>
    <v-card-title class="headline">Move Samples to Shipment</v-card-title>
    <template v-if="loading || progress.active">
      <v-container>
        <LoadingBar :value="progress.amount" />
      </v-container>
    </template>
    <template v-else-if="errors.length">
      <v-card-text v-for="error in errors" :key="error">
        {{ error }}
      </v-card-text>
    </template>
    <template v-else>
      <v-container>
        <v-row>
          <v-col>
            <v-tabs v-model="tab" centered>
              <v-tab>Existing Shipment</v-tab>
              <v-tab>New Shipment</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <LabSelection v-model="lab_id" />
                <ShipmentSelect
                  v-model="shipment_id"
                  :lab_id.sync="lab_id"
                  :clearable="false"
                />
              </v-tab-item>
              <v-tab-item>
                <v-card-title>New Shipment</v-card-title>
                <LabSelection v-model="lab_id" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close" color="error">Cancel</v-btn>
        <v-btn :disabled="!canConfirm" text @click="confirm" color="primary">
          Confirm
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import ShipmentSelect from "@/components/shipments/ShipmentSelect";
import LabSelection from "@/components/labs/LabSelection";
import LoadingBar from "@/components/loaders/LoadingBar";
import { mapActions } from "vuex";

export default {
  name: "SampleShipment",
  props: {
    samples: Array
  },
  components: { ShipmentSelect, LabSelection, LoadingBar },
  data() {
    return {
      loading: false,
      progress: {
        active: false,
        amount: 0
      },
      tab: 0,
      shipment_id: null,
      lab_id: null,
      errors: []
    };
  },
  computed: {
    canConfirm() {
      if (!this.lab_id) return false;
      if (this.tab === 0 && !this.shipment_id) return false;
      return true;
    }
  },
  methods: {
    ...mapActions(["notify"]),
    ...mapActions("baseData", ["getLabs"]),
    ...mapActions("samples", ["updateSample"]),
    ...mapActions("shipments", ["createShipment"]),
    executeProgress(start = true) {
      this.progress.amount = 0;
      this.progress.active = start;
    },
    confirm() {
      this.executeProgress();
      this.errors = [];
      const amount = this.samples.length + 1;
      let completed = 0;

      const shipment = !this.shipment_id
        ? this.createShipment()
        : { id: this.shipment_id };

      Promise.resolve(shipment).then(shipment => {
        completed += 1;
        // Map the data we need for the updates
        this.samples.map(sample => {
          return this.updateSample({
            id: sample.id,
            lab_id: this.lab_id,
            shipments: [
              {
                id: shipment.id,
                lab_id: this.lab_id
              }
            ]
          })
            .then(res => {
              if (res === false) {
                this.setError(`Error updating Sample #${sample.id}`, sample);
              }
            })
            .catch(e => {
              this.setError(`Error updating Sample #${sample.id}`, [sample, e]);
            })
            .finally(() => {
              completed += 1;
              this.progress.amount = ((completed / amount) * 100).toFixed(3);
              if (completed === amount) this.complete();
            });
        });
      });
    },
    setError(message, log = null) {
      console.error(message, log);
      this.errors.push(message);
      this.notify({ message, attrs: { color: "error" } });
    },
    complete() {
      this.executeProgress(false);
      this.notify({
        message: `Successfully updated ${this.samples.length -
          this.errors.length} samples`
      });
      this.close(true);
    },
    close(update = false) {
      this.$emit("close", update);
    }
  },
  mounted() {
    this.loading = true;
    this.getLabs().finally(() => (this.loading = false));
  },
  watch: {
    tab(newVal) {
      if (newVal === 1) {
        this.shipment_id = null;
      }
    },
    shipment_id() {
      // We don't use existing shipments for new shipment tab
      if (this.tab === 1 && this.shipment_id !== null) {
        this.shipment_id = null;
      }
    }
  }
};
</script>

<style scoped></style>
