<template>
  <v-dialog v-model="status" width="500">
    <v-card>
      <v-card-text v-if="loading || value === false">
        <LoadingBar />
      </v-card-text>
      <template v-else>
        <v-card-title class="headline">Confirm</v-card-title>
        <v-card-text>Are you sure you want to cancel this sample?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="status = false">No</v-btn>
          <v-btn text @click="() => setSampleStatus(0)">Yes, Cancel</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import LoadingBar from "@/components/loaders/LoadingBar";

export default {
  name: "SampleToggleDialog",
  components: { LoadingBar },
  props: ["value"],
  data() {
    return {
      status: false,
      loading: false
    };
  },
  watch: {
    status(newVal) {
      // If the status was set to false, remove the sample value
      if (!newVal) {
        this.setValue(false);
      }
    },
    value(newVal, oldVal) {
      // If we got handed a sample, activate the dialog
      if (!!newVal && oldVal === false) {
        this.status = true;
        // If the sample is inactive, automatically activate it
        if (!newVal.is_active) {
          this.setSampleStatus(true);
        }
      }
    }
  },
  methods: {
    ...mapActions(["notify"]),
    ...mapActions("samples", ["updateSample"]),
    setValue(val) {
      this.$emit("input", val);
    },
    setSampleStatus(status) {
      this.loading = true;
      this.$emit("sample-update", { loading: true });
      const data = {
        id: this.value.id,
        is_active: status
      };
      this.updateSample(data)
        .then(res => {
          if (res === false) this.setError();
          this.$emit("sample-update", { success: !!res, data });
        })
        .catch(e => {
          console.error(e);
          this.setError();
          this.$emit("sample-update", { success: false, error: e });
        })
        .finally(() => {
          this.$nextTick(() => {
            this.setValue(false);
            this.status = false;
            this.loading = false;
          });
        });
    },
    setError() {
      this.notify({
        message: "Failed to update sample, please try again",
        attrs: { color: "error" }
      });
    }
  }
};
</script>

<style scoped></style>
